// stores/auth.js
import { defineStore } from 'pinia';
import { useFetch } from 'nuxt/app';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isLoggedIn: false,
    user: null, // Store user data if needed
  }),
  persist : true,
  actions: {
    async login(credentials) {
      try {
        const { data, error } = await useFetch('/api/login', {
          method: 'POST',
          body: credentials,
        });

        if (data.value && data.value.success) {
          this.isLoggedIn = true;
          this.user = data.value.user; // Store user data if needed
          // Set token or user data in localStorage or cookies if necessary
        } else {  
          this.isLoggedIn = false;        
          console.error('Login failed:', error.value);
          throw new Error('Login failed');
        }
      } catch (error) {
        console.error('Login error:', error);
        throw new Error('Login failed');
      }
    },
    logout() {
      this.isLoggedIn = false;
      this.user = null;
      // Remove token or user data from localStorage or cookies
    },
  },
});
